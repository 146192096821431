<template>
    <div>
        <div class="lv_flexalign_center" style="margin-bottom: 15px">
            <el-date-picker
                v-model="time"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
            ></el-date-picker>
            <el-input v-model="params.name" placeholder="搜索手机号、昵称" />
            <el-select v-model="params.isExpired" placeholder="请选择">
                <el-option value="" label="全部状态" />
                <el-option :value="1" label="已过期" />
                <el-option :value="0" label="未过期" />
            </el-select>
            <el-button
                type="primary"
                @click="
                    params.pageNum = 1;
                    getList();
                "
            >
                搜索
            </el-button>
        </div>
        <div style="margin: 20px 0">
            <h1 style="font-size: 40px">{{ total }}</h1>
            <div style="margin-top: 10px">总计用户数</div>
        </div>
        <el-table :data="list" style="width: 100%">
            <el-table-column prop="name" label="机构名称"></el-table-column>
            <el-table-column prop="principal" label="负责人"></el-table-column>
            <el-table-column prop="phone" label="手机"></el-table-column>
            <el-table-column prop="area" label="地区"></el-table-column>
            <el-table-column prop="version" label="版本"></el-table-column>
            <el-table-column
                prop="expiration"
                label="到期时间"
            ></el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    {{ scope.row.isExpired ? "已过期" : "未过期" }}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" @click="extendTime(scope.row.id)">
                        续费
                    </el-button>
                    <el-button type="text" @click="edit(scope.row)">
                        编辑
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="续费" :visible.sync="extendData.show" width="300px">
            <el-date-picker
                v-model="extendData.expiration"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
            ></el-date-picker>
            <span slot="footer" class="dialog-footer">
                <el-button @click="extendData.show = false">取消</el-button>
                <el-button type="primary" @click="confirmTime">确定</el-button>
            </span>
        </el-dialog>

        <el-pagination
            background
            layout="prev, pager, next,sizes"
            :total="total"
            :page-sizes="[10, 50, 100, 200]"
            :current-page.sync="params.pageNum"
            :page-size.sync="params.pageSize"
            @size-change="getList"
            @current-change="getList"
            style="margin-top: 15px"
        ></el-pagination>

        <el-dialog title="转正" :visible.sync="formalData.show" width="500px">
            <el-form label-width="80px">
                <el-form-item label="学校logo">
                    <el-upload
                        accept=".png,.jpg,.jpeg"
                        :show-file-list="false"
                        action=""
                        :before-upload="
                            file => {
                                handleUpload(file);
                                return false;
                            }
                        "
                    >
                        <div v-if="formalData.logo">
                            <img
                                :src="formalData.logo | imgFormat"
                                class="review-img"
                                style="height: auto"
                            />
                            <i
                                class="el-icon-close"
                                @click="formalData.logo = ''"
                                @click.stop=""
                            ></i>
                        </div>
                        <i
                            v-else
                            class="el-icon-plus avatar-uploader-icon"
                            style="font-size: 30px"
                        ></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="学校名称">
                    <el-input v-model="formalData.name"></el-input>
                </el-form-item>
                <el-form-item label="成交金额">
                    <el-input v-model="formalData.cost"></el-input>
                </el-form-item>
                <el-form-item label="有效期">
                    <el-date-picker
                        v-model="formalData.expiration"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="选择日期"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="签约版本">
                    <el-radio
                        v-model="formalData.versionId"
                        :label="item.id"
                        v-for="item in versionList"
                        :key="item.id"
                    >
                        {{ item.version }}
                    </el-radio>
                </el-form-item>
                <el-form-item>
                    <el-button @click="confirmFormal">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    created() {
        this.getList();
    },
    data() {
        return {
            versionList: [],
            extendData: {
                id: "",
                expiration: "",
                show: false,
            },
            list: [],
            total: 0,
            time: [],
            params: {
                pageNum: 1,
                pageSize: 10,
                name: "",
                isExpired: "",
                timeStart: "",
                timeEnd: "",
            },

            formalData: {
                id: "",
                expiration: "",
                show: false,
                name: "",
                versionId: "",
                cost: 0,
                logo: "",
            },
            versionList: [],
            ossUploadData: {},
        };
    },
    mounted() {
        this.getKey();

        this.getVersionList();
    },
    methods: {
        getKey() {
            this.$ajax
                .post("/user/oss/getKey?pathName=cover")
                .then(async res => {
                    this.ossUploadData.cover = res.data;
                });

            this.$ajax
                .post("/user/oss/getKey?pathName=common")
                .then(async res => {
                    this.ossUploadData.material = Object.assign(res.data, {
                        path: "common/" + new Date().getTime() + "/",
                    });
                });
        },
        getVersionList() {
            this.$ajax
                .post("/basic/version/getPage", {
                    pageNum: 1,
                    pageSize: 100,
                })
                .then(res => {
                    this.versionList = res.list;
                });
        },
        confirmTime() {
            this.$ajax.post("/institution/renew", this.extendData).then(res => {
                this.$message.success("保存成功");
                this.extendData.show = false;
                this.getList();
            });
        },
        extendTime(id) {
            this.extendData = {
                id,
                expiration: "",
                show: true,
            };
        },
        getList() {
            if (this.time.length) {
                this.params.timeStart = this.time[0];
                this.params.timeEnd = this.time[1];
            } else {
                this.params.timeStart = "";
                this.params.timeEnd = "";
            }
            this.$ajax.post("/institution/getList", this.params).then(res => {
                this.list = res.list;
                this.total = res.total;
            });
        },

        edit(row) {
            this.formalData = {
                id: row.id,
                expiration: row.expiration.split(" ")[0],
                show: true,
                name: row.name,
                versionId: row.versionId - 0,
                cost: row.cost,
            };
        },
        //单文件上传文件
        handleUpload(file) {
            this.getUploadPromise(
                {
                    file,
                },
                this.ossUploadData.cover
            ).then(res => {
                this.$set(this.formalData, "logo", res.addr);
            });
        },
        confirmFormal() {
            this.$ajax.post("/institution/renew", this.formalData).then(res => {
                this.$message.success("保存成功");
                this.formalData.show = false;
                this.getList();
            });
        },
    },
};
</script>

<style></style>
